import {put, take, takeLatest, call} from "redux-saga/effects"
import {userSlice} from './userSlice'
import {network} from '../../utils/network'
import {appSlice} from '../app/appSlice'
import {endpointSlice} from '../endpoint/endpoingSlice'
import * as Sentry from '@sentry/react'

function* fetchUser(payload) {
  yield put(userSlice.actions.setLoading(true))
  const {access, refresh} = payload

  try {
    network.axiosUpdate(access, refresh)

    const balancesRes = yield network.axios({
      method: 'GET',
      url: '/user-balance/',
    })
    const balancesData = balancesRes.data
    const balances = balancesData

    const permissionsRes = yield network.axios({
      method: 'GET',
      url: '/user-merchant/',
    })

    const limitsRes = yield network.axios({
      method: 'GET',
      url: '/user-limit/',
    })
    const limits = typeof limitsRes.data === 'object' && limitsRes.data.length > 0 ? limitsRes.data[0] : null

    yield put(endpointSlice.actions.fetch())
    yield take(endpointSlice.actions.selectEndpoint.type)

    const permissionsData = permissionsRes.data

    const permissions = {
      user_id: permissionsData[0].user_id,
      user_data: permissionsData[0].user_data,
      endpoints: {},
    }

    Sentry.setUser({
      id: permissions.user_id,
      username: permissions.user_data.name
    });

    const posthog = window.posthog
    if (posthog) {
      if (typeof posthog.capture === 'function') {
        posthog.capture('user_signed_up', {
          user_id: permissions.user_id,
          user_name: permissions.user_data.name,
          merchant_id: permissions.merchant_id
        })
      }

      if (typeof posthog.setPersonProperties === 'function') {
        posthog.setPersonProperties(
          {name: permissions.user_data.name}
        )
      }
    }

    permissionsData.forEach(permission => {
      permission.endpoint_permissions.forEach(ep => {
        if (!permissions.endpoints[ep.endpoint_id]) {
          permissions.endpoints[ep.endpoint_id] = {}
        }
        const {
          merchant_settings,
          merchant_passport_check_counter,
          merchant_personal_account_settings,
          merchant_id
        } = permission
        permissions.endpoints[ep.endpoint_id] = {
          merchant_settings,
          merchant_passport_check_counter,
          personal_account_settings: merchant_personal_account_settings?.[0] || null,
          merchant_id
        }
        const {user_id, endpoint_id, ...rest} = ep
        Object.entries(rest).forEach(([key, value]) => {
          if (value) {
            permissions.endpoints[ep.endpoint_id][key] = true
          } else {
            permissions.endpoints[ep.endpoint_id][key] = false
          }
        })
      })
    })

    const response = yield network.axios({
      method: 'GET',
      url: '/directories/',
    })
    yield put(appSlice.actions.setDirectories(response.data))

    localStorage.setItem('tokens', JSON.stringify({access, refresh}))
    localStorage.setItem('permissions', JSON.stringify(permissions))
    localStorage.setItem('allPermissions', JSON.stringify(permissionsData))


    yield put(userSlice.actions.setUser({
      user: {},
      tokens: {access, refresh},
      permissions,
      balances,
      limits,
      allPermissions: permissionsData
    }))
  } catch (e) {
    console.error(e)

    if (e?.code === 'ERR_NETWORK') {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Ошибка доступа к серверу',
      }))
    } else {
      if (e?.response?.status === 401) {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: e?.response?.data?.detail ?? 'Что-то пошло не так',
        }))
      } else {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: 'Что-то пошло не так',
        }))
      }
    }
  }
  yield put(userSlice.actions.setLoading(false))
}

function* restore() {
  yield put(userSlice.actions.setLoading(true))

  try {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    if (!tokens || !permissions) {
      yield put(userSlice.actions.logout())
      yield put(userSlice.actions.setLoading(false))
      return
    }

    const {access, refresh} = tokens
    if (!access || !refresh) {
      yield put(userSlice.actions.logout())
      yield put(userSlice.actions.setLoading(false))
      return
    }

    yield call(fetchUser, {access, refresh})

  } catch (e) {
    console.error(e)
  }

  yield put(userSlice.actions.setLoading(false))
}

function* login({payload}) {
  yield put(userSlice.actions.setLoading(true))
  const {username, password} = payload
  try {
    const tokenRes = yield network.axios({
      method: 'POST',
      url: '/token/',
      data: {
        username,
        password,
      }
    })
    const {access, refresh, otp_enabled} = tokenRes.data

    if (otp_enabled && !access && !refresh) {
      yield put(userSlice.actions.setOtp(tokenRes.data))
    } else {
      yield call(fetchUser, {access, refresh})
    }

  } catch (e) {
    console.error(e)

    if (e?.code === 'ERR_NETWORK') {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Ошибка доступа к серверу',
      }))
    } else {
      if (e?.response?.status === 401) {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: e?.response?.data?.detail ?? 'Что-то пошло не так',
        }))
      } else {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: 'Что-то пошло не так',
        }))
      }
    }
    yield put(userSlice.actions.logout())
  }

  yield put(userSlice.actions.setLoading(false))
}


function* otpCheck({payload}) {
  yield put(userSlice.actions.setLoading(true))
  const {code, username} = payload

  try {
    const tokenRes = yield network.axios({
      method: 'POST',
      url: '/otp/check/',
      data: {
        code,
        username,
      }
    })
    const {access, refresh} = tokenRes.data
    yield call(fetchUser, {access, refresh})

  } catch (e) {
    if (e?.response?.data?.message === 'Code has expired') {
      yield put(userSlice.actions.logout())
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.detail ?? 'Что-то пошло не так',
      }))
      yield put(userSlice.actions.setLoading(false))
      return
    }
    if (e?.response?.data?.message === 'User disabled') {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.detail ?? 'Что-то пошло не так',
      }))
      yield put(userSlice.actions.setUserDisabled(true))
      yield put(userSlice.actions.setLoading(false))
      return
    }
    if (e?.code === 'ERR_NETWORK') {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Ошибка доступа к серверу',
      }))
    } else {
      if (e?.response?.status === 401) {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: e?.response?.data?.detail ?? 'Что-то пошло не так',
        }))
      } else {
        yield put(appSlice.actions.addMessage({
          type: 'error',
          text: 'Что-то пошло не так',
        }))
      }
    }
  }

  yield put(userSlice.actions.setLoading(false))
}

function* logout() {
  Sentry.setUser(null);
  localStorage.removeItem('tokens')
  localStorage.removeItem('permissions')
  yield put(userSlice.actions.setUser({
    user: null,
    tokens: null,
    permissions: null,
    balances: null,
    limits: null,
    otp: null
  }))
}

export function* userSagas() {
  yield takeLatest(userSlice.actions.restore.type, restore)
  yield takeLatest(userSlice.actions.logout.type, logout)
  yield takeLatest(userSlice.actions.login.type, login)
  yield takeLatest(userSlice.actions.otpCheck.type, otpCheck)
}
