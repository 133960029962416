import {createSlice} from "@reduxjs/toolkit"
import {bidBlocks, defaultBidBlocks} from '../../config/bidBlocks'


const initialState = {
  loading: true,
  directories: {
    bid_requisite_type_list: [],
    sbp_bank_list: [],
    file_document_type_list: [],
    car_make_list: [],
    catalogue_item_list: [],
    county_list: [],
  },
  dictionary: {
    favoriteCatalogueItems: [],
    catalogueItems: [],
    countyList: [],
    fileDocuments: {
      scrap: null,
      passport_main_page: null,
      passport_registration_page: null,
      offer_signed: null,
      offer: null,
    },
    bidRequisites: {
      pan: null,
      phone: null,
      email: null,
    },
    scrapClassList: [],
  },
  settings: {
    bidBlocks,
  },
  messages: [
    // {
    //   type: 'info',
    //   text: 'hell',
    //   autoClose: true,
    // }
  ],
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    restore: () => {
      //
    },
    setDirectories: (state, action) => {
      const {payload} = action
      state.directories = payload

      const countyList = payload.county_list.map(item => ({
        ...item,
        label: item?.name,
        value: item?.alpha2,
        code: item?.alpha2,
      }))

      const favorite = countyList
        .filter(c => c.is_favorite)
        .sort((a, b) => a.label.localeCompare(b.label))

      const other = countyList
        .filter(c => !c.is_favorite)
        .sort((a, b) => a.label.localeCompare(b.label))

      state.dictionary.countyList = [...favorite, ...other]

      state.dictionary.catalogueItems = payload.catalogue_item_list
        .filter(item => item.prices.length)
        .map(item => ({
          id: item.id,
          label: item.name,
          is_favorite: item.is_favorite,
          currency: item.prices[0].currency,
          price_per_unit: item.prices[0].price_per_unit,
          unit_of_measure: item.prices[0].unit_of_measure,
          scrap_class_id: item.scrap_class_id,
          scrap_class_name: item.scrap_class_name,
          merchant_id: item?.merchant_id
        }))

      state.dictionary.favoriteCatalogueItems = state.dictionary.catalogueItems.filter(item => item.is_favorite)

      state.directories.scrapClassList = payload.scrap_class_list

      Object.keys(state.dictionary.bidRequisites).forEach(key => {
        const item = payload.bid_requisite_type_list.find(item => item?.name === key)
        state.dictionary.bidRequisites[key] = {
          ...item,
          label: item?.name,
        }
      })

      Object.keys(state.dictionary.fileDocuments).forEach(key => {
        const item = payload.file_document_type_list.find(item => item?.name === key)
        state.dictionary.fileDocuments[key] = {
          ...item,
          label: item?.name,
        }
      })
    },
    setLoading(state, action) {
      const {payload} = action
      state.loading = payload
    },
    addMessage(state, action) {
      const {payload} = action
      const message = {
        key: `${Date.now().toString(36) + Math.random().toString(36)}`,
        type: 'info',
        text: '',
        autoClose: true,
        ...payload,
      }
      state.messages = [
        ...state.messages,
        message,
      ]
    },
    removeMessage(state, action) {
      const {payload} = action
      state.messages = state.messages.filter(message => message.key !== payload.key)
    },
  },
})

const appReducer = appSlice.reducer

export {appSlice, appReducer}
