import PropTypes from 'prop-types';
// import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
// import axios, { endpoints } from 'src/utils/axios';
//
import {AuthContext} from './auth-context';
// import { isValidToken, setSession } from './utils';
import {useDispatch, useSelector} from 'react-redux'
import {userSlice} from '../../../store/user/userSlice'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

// const initialState = {
//   user: null,
//   loading: true,
// };
//
// const reducer = (state, action) => {
//   if (action.type === 'INITIAL') {
//     return {
//       loading: false,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === 'LOGIN') {
//     return {
//       ...state,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === 'REGISTER') {
//     return {
//       ...state,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === 'LOGOUT') {
//     return {
//       ...state,
//       user: null,
//     };
//   }
//   return state;
// };

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({children}) {
  const auth = useSelector(state => state.user)
  const app = useSelector(state => state.app)
  const dispatch = useDispatch()

  const context = {
    ...auth,
    loading: auth.loading || app.loading,
    authenticated: !!auth.user,
    unauthenticated: !auth.user,
    login: (username, password) => dispatch(userSlice.actions.login({username, password})),
    otpCheck: (code, username) => dispatch(userSlice.actions.otpCheck({code, username})),
    logout: () => dispatch(userSlice.actions.logout()),
    // register: () => dispatch(authSlice.actions.login()),
  }


  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
